@lost gutter 25px;
#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button {
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  z-index: 10;
}
#cssmenu:after,
#cssmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
#cssmenu {
  width: auto;
  line-height: 1;
  background: #fff;
}
#menu-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  background: #00f;
  transition: all 0.25s ease-out;
}
#cssmenu > ul > li {
  float: left;
}
#cssmenu.align-center > ul {
  font-size: 0;
  text-align: center;
}
#cssmenu.align-center > ul > li {
  display: inline-block;
  float: none;
}
#cssmenu.align-center ul ul {
  text-align: left;
}
#cssmenu.align-right > ul > li {
  float: right;
}
#cssmenu.align-right ul ul {
  text-align: right;
}
#cssmenu > ul > li > a {
  padding: 20px;
  font-size: 0.85em;
  text-decoration: none;
  text-transform: uppercase;
  color: #00f;
  transition: color 0.2s ease;
}
#cssmenu > ul > li:hover > a,
#cssmenu > ul > li > a.active {
  color: #a52a2a;
}
#cssmenu > ul > li.has-sub > a {
  padding-right: 25px;
}
#cssmenu > ul > li.has-sub > a::after {
  position: absolute;
  top: 21px;
  right: 10px;
  width: 4px;
  height: 4px;
  border-bottom: 1px solid #00f;
  border-right: 1px solid #00f;
  content: "";
  transform: rotate(45deg);
  transition: border-color 0.2s ease;
}
#cssmenu > ul > li.has-sub:hover > a::after {
  border-color: #a52a2a;
}
#cssmenu ul ul {
  position: absolute;
  left: -9999px;
}
#cssmenu li:hover > ul {
  left: auto;
}
#cssmenu.align-right li:hover > ul {
  right: 0;
}
#cssmenu ul ul ul {
  margin-left: 100%;
  top: 0;
}
#cssmenu.align-right ul ul ul {
  margin-left: 0;
  margin-right: 100%;
}
#cssmenu ul ul li {
  height: 0;
  transition: height 0.2s ease;
}
#cssmenu ul li:hover > ul > li {
  height: auto;
}
#cssmenu ul ul li a {
  padding: 8px 10px 10px;
  width: 180px;
  font-size: 0.9em;
  line-height: 1.3em;
  background: #00f;
  text-decoration: none;
  color: #fff;
  margin-bottom: 2px;
  transition: color 0.2s ease;
}
#cssmenu ul ul li a:hover {
  background-color: #a52a2a;
}
#cssmenu ul ul li a:last-child {
  border-bottom: 0;
}
@media (max-width: 1130px) {
  #cssmenu {
    width: 100%;
  }
  #cssmenu ul {
    width: 100%;
/*display none*/
  }
  #cssmenu.align-center > ul,
  #cssmenu.align-right ul ul {
    text-align: left;
  }
  #cssmenu ul li,
  #cssmenu ul ul li,
  #cssmenu ul li:hover > ul > li {
    width: 100%;
    height: auto;
    border-top: 1px solid rgba(120,120,120,0.15);
  }
  #cssmenu ul li a,
  #cssmenu ul ul li a {
    width: 100%;
  }
  #cssmenu > ul > li,
  #cssmenu.align-center > ul > li,
  #cssmenu.align-right > ul > li {
    float: none;
    display: block;
  }
  #cssmenu ul ul li a {
    padding: 20px 20px 20px 20px;
    font-size: 1em;
    color: #00f;
    background: none;
  }
  #cssmenu ul ul li a:hover {
    background-color: #fff;
  }
  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li a:hover {
    color: #a52a2a;
  }
  #cssmenu ul ul ul li a {
    padding-left: 40px;
  }
  #cssmenu ul ul,
  #cssmenu ul ul ul {
    position: relative;
    left: 0;
    right: auto;
    width: 100%;
    margin: 0;
  }
  #cssmenu > ul > li.has-sub > a::after,
  #cssmenu ul ul li.has-sub > a::after {
    display: none;
  }
  #menu-line {
    display: none;
  }
  #cssmenu #menu-button {
    display: block;
    padding: 20px;
    color: #000;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
  }
  #cssmenu #menu-button::after {
    content: '';
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 20px;
    height: 2px;
    background: #a52a2a;
  }
  #cssmenu #menu-button::before {
    content: '';
    position: absolute;
    top: 25px;
    right: 20px;
    display: block;
    width: 20px;
    height: 3px;
    border-top: 2px solid #a52a2a;
    border-bottom: 2px solid #a52a2a;
  }
  #cssmenu .submenu-button {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid rgba(120,120,120,0.15);
    height: 52px;
    width: 52px;
    cursor: pointer;
  }
  #cssmenu .submenu-button::after {
    content: '';
    position: absolute;
    top: 21px;
    left: 26px;
    display: block;
    width: 1px;
    height: 11px;
    background: #a52a2a;
    z-index: 99;
  }
  #cssmenu .submenu-button::before {
    content: '';
    position: absolute;
    left: 21px;
    top: 26px;
    display: block;
    width: 11px;
    height: 1px;
    background: #a52a2a;
    z-index: 99;
  }
}
* {
  margin: 0px;
  border: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body,
html {
  height: 100%;
}
body {
  width: 100%;
  position: relative;
  right: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 90%;
  line-height: 1.7;
  background-color: #fff;
}
em {
  font-style: italic;
}
p {
  margin-bottom: 1.6em;
}
h1,
h2,
h3 {
  font-family: "Open Sans Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.2em;
}
h1 {
  font-size: 2em;
  line-height: 1.1em;
  color: #ff7f4b;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ff7f4b;
}
h2 {
  font-size: 1.4em;
  line-height: 1.3em;
  color: #808991;
  margin-top: 2.4em;
  margin-bottom: 0.8em;
}
a {
  text-decoration: none;
  outline: none;
  color: #808991;
  transition: all 0.2s linear;
}
a:hover,
a:active,
a:focus,
a.active {
  text-decoration: none;
  outline: none;
  color: #ff7f4b;
  transition: all 0.2s linear;
}
.left {
  float: left;
}
.right {
  float: right;
}
.wrapper {
  min-height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
header {
  width: 100%;
  height: 80px;
  min-height: 80px;
  background-color: #fff;
  display: block;
  align-items: flex-start;
  padding: 20px 40px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.5);
  z-index: 10;
  transition: all 0.5s linear;
}
@media screen and (max-width: 1040px) {
  header {
    padding: 15px 20px;
  }
}
header a {
  z-index: 50;
}
header a .logo {
  width: calc((100% - 80px) - 120px);
  height: 40px;
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  white-space: pre-line;
  transition: all 0.5s linear;
}
@media screen and (max-width: 1040px) {
  header a .logo {
    width: calc((100% - 40px) - 120px);
  }
}
header a .logo img {
  height: 100%;
  width: auto;
}
header a .logo h1 {
  font-size: 2em;
  display: flex;
  align-self: flex-end;
  margin-bottom: 0;
  border: 0px;
  padding: 0px;
  transition: all 0.5s linear;
}
@media screen and (max-width: 570px) {
  header a .logo h1 {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 470px) {
  header a .logo h1 {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 370px) {
  header a .logo h1 {
    font-size: 1em;
  }
}
.title-cookie header a .logo h1 {
  font-size: 2.8em;
  position: relative;
  top: 6px;
}
@media screen and (max-width: 570px) {
  .title-cookie header a .logo h1 {
    font-size: 2.4em;
    top: 8px;
  }
}
@media screen and (max-width: 470px) {
  .title-cookie header a .logo h1 {
    font-size: 1.9em;
    top: 4px;
  }
}
@media screen and (max-width: 370px) {
  .title-cookie header a .logo h1 {
    font-size: 1.4em;
    top: 3px;
  }
}
.title-dancing header a .logo h1 {
  font-size: 2.4em;
  position: relative;
  top: 3px;
}
@media screen and (max-width: 570px) {
  .title-dancing header a .logo h1 {
    font-size: 2.2em;
  }
}
@media screen and (max-width: 470px) {
  .title-dancing header a .logo h1 {
    font-size: 1.7em;
    top: 0px;
  }
}
@media screen and (max-width: 370px) {
  .title-dancing header a .logo h1 {
    font-size: 1.2em;
  }
}
header .hamburger {
  outline: none;
  position: absolute;
  top: 38px;
  right: 38px;
  padding: 0;
  transition: all 0.5s linear;
}
@media screen and (max-width: 1040px) {
  header .hamburger {
    right: 18px;
  }
}
header .hamburger .hamburger-box {
  outline: none;
}
header .hamburger .hamburger-box .hamburger-inner {
  margin-top: 6px;
  height: 2px;
}
header .hamburger .hamburger-box .hamburger-inner::before,
header .hamburger .hamburger-box .hamburger-inner::after {
  outline: none;
  height: 2px;
}
header .menu {
  margin-top: 30px;
}
header .menu ul {
  list-style: none;
}
header .menu ul li {
  display: flex;
  flex-direction: column;
  text-align: right;
}
header .menu ul li a {
  text-transform: uppercase;
  font-size: 0.95em;
  padding: 7px 0 0 30px;
  transition: all 0.1s linear;
}
header .menu ul li a:hover {
  padding: 7px 20px 0 30px;
  transition: all 0.1s linear;
}
header .languages {
  position: absolute;
  top: 46px;
  right: 93px;
  z-index: 50;
  transition: all 0.5s linear;
}
@media screen and (max-width: 1040px) {
  header .languages {
    right: 63px;
  }
}
header .languages.moved {
  right: 33px;
}
@media screen and (max-width: 1040px) {
  header .languages.moved {
    right: 13px;
  }
}
header .languages ul {
  display: flex;
  flex-direction: row;
}
header .languages ul li {
  height: 10px;
  position: relative;
  top: 8px;
  list-style: none;
  border-right: 1px solid #333;
}
header .languages ul li.active a {
  color: #ff7f4b;
}
header .languages ul li:last-child {
  border-right: 0px;
}
header .languages ul li a {
  position: relative;
  top: -8px;
  font-size: 0.8em;
  text-transform: uppercase;
  padding: 0 7px;
}
header.smaller {
  height: 50px;
  min-height: 50px;
  padding: 10px 40px;
}
@media screen and (max-width: 1040px) {
  header.smaller {
    padding: 10px 20px;
  }
}
header.smaller a .logo {
  height: 30px;
  transition: all 0.5s linear;
}
header.smaller a .logo h1 {
  font-size: 1.8em;
  transition: all 0.5s linear;
}
@media screen and (max-width: 570px) {
  header.smaller a .logo h1 {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 470px) {
  header.smaller a .logo h1 {
    font-size: 1em;
  }
}
@media screen and (max-width: 370px) {
  header.smaller a .logo h1 {
    font-size: 0.8em;
  }
}
.title-cookie header.smaller a .logo h1 {
  font-size: 2.2em;
}
@media screen and (max-width: 570px) {
  .title-cookie header.smaller a .logo h1 {
    font-size: 2em;
  }
}
@media screen and (max-width: 470px) {
  .title-cookie header.smaller a .logo h1 {
    font-size: 1.7em;
  }
}
@media screen and (max-width: 370px) {
  .title-cookie header.smaller a .logo h1 {
    font-size: 1.2em;
  }
}
.title-dancing header.smaller a .logo h1 {
  font-size: 2em;
}
@media screen and (max-width: 570px) {
  .title-dancing header.smaller a .logo h1 {
    font-size: 1.8em;
  }
}
@media screen and (max-width: 470px) {
  .title-dancing header.smaller a .logo h1 {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 370px) {
  .title-dancing header.smaller a .logo h1 {
    font-size: 1em;
  }
}
header.smaller .hamburger {
  top: 14px;
  transition: all 0.5s linear;
}
header.smaller .languages {
  top: 22px;
  transition: all 0.5s linear;
}
header.is-active {
  height: auto;
  min-height: 80px;
  display: block;
  padding-bottom: 30px;
  transition: all 0.5s linear;
}
header.is-active.smaller {
  min-height: 50px;
}
.content {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 200px;
}
@media screen and (max-width: 768px) {
  .content {
    padding-bottom: 340px;
  }
}
.content a {
  color: #ff7f4b;
  font-weight: 400;
}
.content ul {
  padding-left: 15px;
  margin-bottom: 1.6em;
  list-style: none;
}
.content ul li::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-left: -13px;
  margin-right: 8px;
  position: relative;
  top: -2px;
  background-color: #808991;
}
.content figure {
  width: auto;
  margin-bottom: 10px;
}
.content figure img {
  max-width: 100%;
}
.content figure.portraitLeft {
  float: left;
  margin-right: 15px;
}
.content figure.portraitRight {
  float: right;
  margin-left: 15px;
}
.content .landscape {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1040px) {
  .content .landscape {
    height: 100vh;
  }
}
.content .landscape.home {
  height: 100vh;
  position: relative;
  line-height: 2.7em;
}
.content .landscape.home img {
  width: 60px;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  left: calc((100% - 60px) / 2);
  right: calc((100% - 60px) / 2);
  cursor: pointer;
}
.content .landscape.home .claim {
  width: auto;
  max-width: calc(100% - 80px);
  display: none;
  color: rgba(255,255,255,0.85);
  text-align: center;
  font-family: "Open Sans Condensed";
  letter-spacing: 0.2em;
  font-size: 2.7em;
  line-height: 1.1em;
  text-transform: uppercase;
  margin: 0 auto;
  text-shadow: 1px 1px 1px #000;
}
@media screen and (max-width: 1040px) {
  .content .landscape.home .claim {
    max-width: calc(100% - 40px);
  }
}
@media screen and (max-width: 768px) {
  .content .landscape.home .claim {
    font-size: 2.4em;
  }
}
@media screen and (max-width: 667px) {
  .content .landscape.home .claim {
    font-size: 2.1em;
  }
}
@media screen and (max-width: 375px) {
  .content .landscape.home .claim {
    font-size: 1.8em;
  }
}
.content .text {
  width: 100%;
  max-width: 1040px;
  display: block;
  overflow: hidden;
  margin: 0 auto;
  padding: 40px 20px 30px;
}
.content .text p:last-of-type {
  margin-bottom: 0.4em;
}
.content .text.light-background {
  background-color: #e9e9e9;
  padding: 100px 0 120px;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.3);
}
.content .text .gallery {
  display: block;
  background-color: #e9e9e9;
  overflow: hidden;
  padding: 10px;
  margin: 10px 0;
}
.content .text .gallery h1 {
  margin-bottom: 0.35em;
}
.content .text .gallery h2 {
  margin-bottom: 0.35em;
}
.content .text .gallery ol {
  list-style-position: inside;
}
.content .text .gallery:first-of-type {
  margin-top: 50px;
}
.content .text .gallery .images {
  width: 100%;
  max-width: 1000px;
  padding: 0;
  margin: 0 auto;
  margin-top: 5px;
  flex-wrap: wrap;
}
.content .text .gallery .images a {
  lost-column: 1/7 7;
  margin-bottom: 0px;
}
@media screen and (max-width: 950px) {
  .content .text .gallery .images a {
    lost-column: 1/6 6;
  }
}
@media screen and (max-width: 775px) {
  .content .text .gallery .images a {
    lost-column: 1/5 5;
  }
}
@media screen and (max-width: 625px) {
  .content .text .gallery .images a {
    lost-column: 1/4 4;
  }
}
@media screen and (max-width: 450px) {
  .content .text .gallery .images a {
    lost-column: 1/3 3;
  }
}
.content .text .gallery .images a img {
  width: 100%;
  max-width: 200px;
  opacity: 0.75;
  transition: all 0.5s linear;
}
.content .text .gallery .images a:hover img {
  opacity: 1;
  transition: all 0.5s linear;
}
.content .text .services h2 {
  margin-top: 0;
}
.content .text .services .id {
  display: block;
  height: 70px;
}
.content .text .area {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1040px) {
  .content .text .area {
    flex-direction: column;
  }
}
.content .text .area ul.services {
  padding-left: 19px;
  margin-bottom: 1.6em;
  list-style: none;
}
.content .text .area ul.services li::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-left: -17px;
  margin-right: 8px;
  position: relative;
  top: -2px;
  background-color: #808991;
}
.content .text .area ul li a {
  color: #333;
  font-weight: 300;
}
.content .text .area ul li a:hover {
  color: #ff7f4b;
}
.content .text .area > div {
  lost-column: 1/3 3;
}
@media screen and (max-width: 1040px) {
  .content .text .area > div {
    lost-column: 1;
    margin-bottom: 30px;
  }
  .content .text .area > div:last-of-type {
    margin-bottom: 0;
  }
}
.content .text .area > div > a {
  display: flex;
  align-items: baseline;
  align-content: center;
  height: auto;
  color: #333;
}
.content .text .area > div > a strong {
  font-weight: 400;
  color: #333;
}
.content .text .area > div > a:hover {
  color: #ff7f4b;
}
.content .text .area a.intro {
  font-family: "Open Sans Condensed";
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 300;
  color: #808991;
  text-align: center;
  border-bottom: 1px solid #808991;
  margin-bottom: 30px;
}
@media screen and (max-width: 1040px) {
  .content .text .area a.intro {
    text-align: left;
  }
}
.content .text .area a.intro.active {
  color: #ff7f4b;
  border-bottom: 1px solid #ff7f4b;
}
.content .picture {
  width: 100%;
  max-width: 1000px;
  height: 100%;
  padding-bottom: 27%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 40px auto 30px;
}
@media screen and (max-width: 1040px) {
  .content .picture {
    background-attachment: local;
    padding-bottom: 50%;
  }
}
@media screen and (max-width: 480px) {
  .content .picture {
    background-attachment: local;
    padding-bottom: 60%;
  }
}
@media screen and (max-width: 375px) {
  .content .picture {
    background-attachment: local;
    padding-bottom: 70%;
  }
}
.content .map-canvas {
  height: 60vh;
  position: relative;
}
.content .map-canvas .gmnoprint {
  top: 80px !important;
}
.content .map-canvas .gmnoprint .gm-style-cc {
  display: none !important;
}
@media screen and (max-width: 1040px) {
  .content .map-canvas {
    padding-bottom: 40%;
  }
}
@media screen and (max-width: 768px) {
  .content .map-canvas {
    padding-bottom: 50%;
  }
}
@media screen and (max-width: 480px) {
  .content .map-canvas {
    padding-bottom: 60%;
  }
}
@media screen and (max-width: 375px) {
  .content .map-canvas {
    padding-bottom: 70%;
  }
}
footer {
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px 40px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,0.5);
}
footer h1,
footer h2,
footer h3 {
  font-size: 1.4em;
  color: #808991;
  margin-bottom: 7px;
  padding-bottom: 2px;
  border-bottom: 0;
}
footer p {
  color: #808991;
}
@media screen and (max-width: 1040px) {
  footer {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  footer {
    min-height: 240px;
    flex-direction: column;
  }
}
footer .contact {
  font-size: 0.8em;
  line-height: 1.4em;
  text-align: left;
}
@media screen and (max-width: 768px) {
  footer .contact {
    text-align: center;
  }
}
footer .contact p {
  margin-bottom: 0;
}
footer .social {
  display: inline-block;
}
footer .social a {
  height: 100%;
  padding: 2px;
}
footer .social a img {
  height: 35px;
  opacity: 0.8;
  transition: all 0.2s linear;
}
footer .social a:hover img {
  opacity: 1;
  transition: all 0.2s linear;
}
footer .credits {
  height: auto;
  font-size: 0.8em;
  line-height: 1.4em;
  text-align: right;
}
@media screen and (max-width: 768px) {
  footer .credits {
    text-align: center;
  }
}
footer .credits p {
  margin-bottom: 0;
}
footer .credits a {
  height: 100%;
  opacity: 0.8;
  transition: all 0.2s linear;
}
footer .credits a:hover {
  opacity: 1;
  transition: all 0.2s linear;
}
footer .credits a img {
  width: auto;
  height: 60px;
}
@media screen and (max-width: 768px) {
  footer .credits a img {
    width: auto;
    height: 16px;
  }
}
.empty-80 {
  width: 100%;
  height: 80px;
  display: block;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .hide-lg {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .hide-sm {
    display: none;
  }
}
.orange {
  color: #ff7f4b;
}
.grey {
  color: #808991;
}
@media print {
  @page {
    size: auto;
    margin: 10mm;
  }
  html {
    margin: 0px;
  }
  body {
    font-size: 75%;
    line-height: 1.6em;
    color: #222;
    position: relative;
  }
  header,
  footer {
    display: none;
  }
  .content .landscape {
    display: none;
  }
  .content .text .area,
  .content .text a.go-back {
    display: none;
  }
}
