@media print

  @page
    size auto
    margin 10mm
  
  html
    margin 0px
  
  body
    font-size 75%
    line-height 1.6em
    color #222
    position relative
  
  header, footer
    display none
  
  .content
    .landscape
      display none
    .text
      .area, a.go-back
        display none