#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button
    list-style none
    line-height 1
    display block
    position relative
    z-index 10

#cssmenu:after,
#cssmenu > ul:after
    content "."
    display block
    clear both
    visibility hidden
    line-height 0
    height 0

#cssmenu
    width auto
    line-height 1
    background #fff

#menu-line
    position absolute
    top 0
    left 0
    height 3px
    background blue
    transition all 0.25s ease-out

#cssmenu > ul > li
    float left

#cssmenu.align-center > ul
    font-size 0
    text-align center

#cssmenu.align-center > ul > li
    display inline-block
    float none

#cssmenu.align-center ul ul
    text-align left

#cssmenu.align-right > ul > li
    float right

#cssmenu.align-right ul ul
    text-align right

#cssmenu > ul > li > a
    padding 20px
    font-size 0.85em
    text-decoration none
    text-transform uppercase
    color blue
    transition color .2s ease

#cssmenu > ul > li:hover > a,
#cssmenu > ul > li > a.active
    color brown

#cssmenu > ul > li.has-sub > a
    padding-right 25px

#cssmenu > ul > li.has-sub > a::after
    position absolute
    top 21px
    right 10px
    width 4px
    height 4px
    border-bottom 1px solid blue
    border-right 1px solid blue
    content ""
    transform rotate(45deg)
    transition border-color 0.2s ease

#cssmenu > ul > li.has-sub:hover > a::after
    border-color brown

#cssmenu ul ul
    position absolute
    left -9999px

#cssmenu li:hover > ul
    left auto

#cssmenu.align-right li:hover > ul
    right 0

#cssmenu ul ul ul
    margin-left 100%
    top 0

#cssmenu.align-right ul ul ul
    margin-left 0
    margin-right 100%

#cssmenu ul ul li
    height 0
    transition height .2s ease

#cssmenu ul li:hover > ul > li
    height auto

#cssmenu ul ul li a
    padding 8px 10px 10px
    width 180px
    font-size 0.9em
    line-height 1.3em
    background blue
    text-decoration none
    color #fff
    margin-bottom 2px
    transition color .2s ease

#cssmenu ul ul li a:hover
    background-color brown

#cssmenu ul ul li a:last-child
    border-bottom 0


/* MOBILE e TABLET
------------------------------------------------------- */
@media (max-width 1130px)
    #cssmenu
        width 100%

    #cssmenu
        ul
            width 100%
            /*display none*/

    #cssmenu.align-center > ul,
    #cssmenu.align-right ul ul
        text-align left

    #cssmenu ul li,
    #cssmenu ul ul li,
    #cssmenu ul li:hover > ul > li
        width 100%
        height auto
        border-top 1px solid rgba(120, 120, 120, 0.15)

    #cssmenu ul li a,
    #cssmenu ul ul li a
        width 100%

    #cssmenu > ul > li,
    #cssmenu.align-center > ul > li,
    #cssmenu.align-right > ul > li
        float none
        display block

    #cssmenu ul ul li a
        padding 20px 20px 20px 20px
        font-size 1em
        color blue
        background none
    
    #cssmenu ul ul li a:hover
        background-color white

    #cssmenu ul ul li:hover > a,
    #cssmenu ul ul li a:hover
        color brown

    #cssmenu ul ul ul li a
        padding-left 40px

    #cssmenu ul ul,
    #cssmenu ul ul ul
        position relative
        left 0
        right auto
        width 100%
        margin 0

    #cssmenu > ul > li.has-sub > a::after,
    #cssmenu ul ul li.has-sub > a::after
        display none

    #menu-line
        display none

    #cssmenu #menu-button
        display block
        padding 20px
        color black
        cursor pointer
        font-size 12px
        text-transform uppercase
    
    /* Menu bar - Prima linea
    ---------------------------------------------------- */
    #cssmenu #menu-button::after
        content ''
        position absolute
        top 20px
        right 20px
        display block
        width 20px
        height 2px
        background brown
    
    /* Menu bar - Seconda e terza linea
    ---------------------------------------------------- */
    #cssmenu #menu-button::before
        content ''
        position absolute
        top 25px
        right 20px
        display block
        width 20px
        height 3px
        border-top 2px solid brown
        border-bottom 2px solid brown

    /* Sottomenu
    ---------------------------------------------------- */
    #cssmenu .submenu-button
        position absolute
        z-index 10
        right 0
        top 0
        display block
        border-left 1px solid rgba(120, 120, 120, 0.15)
        height 52px
        width 52px
        cursor pointer

    #cssmenu .submenu-button::after
        content ''
        position absolute
        top 21px
        left 26px
        display block
        width 1px
        height 11px
        background brown
        z-index 99

    #cssmenu .submenu-button::before
        content ''
        position absolute
        left 21px
        top 26px
        display block
        width 11px
        height 1px
        background brown
        z-index 99

    #cssmenu .submenu-button.submenu-opened:after
        /*display none*/


