/* COLORS
------------------------------------------------------- */
orange = rgb(328, 127, 75)
grey = rgb(128, 137, 145)


/* HTML, BODY
------------------------------------------------------- */
*
  margin 0px
  border 0px
  padding 0px
  box-sizing border-box

body,
html
  height 100%

body
  width 100%
  position relative
  right 0
  font-family "Open Sans", sans-serif
  font-weight 300
  font-size 90%
  line-height 1.7
  background-color white


/* GENERALI
------------------------------------------------------- */
em
  font-style italic
p
  margin-bottom 1.6em

h1, h2, h3
  font-family "Open Sans Condensed", sans-serif
  text-transform uppercase
  font-weight 300
  letter-spacing 0.2em
h1
  font-size 2em
  line-height 1.1em
  color orange
  padding-bottom 10px
  margin-bottom 30px
  border-bottom 1px solid orange
h2
  font-size 1.4em
  line-height 1.3em
  color grey
  margin-top 2.4em
  margin-bottom 0.8em
a
  text-decoration none
  outline none
  color grey
  transition all 0.2s linear
a:hover, a:active, a:focus, a.active
  text-decoration none
  outline none
  color orange
  transition all 0.2s linear

.left
  float left
.right
  float right


/* WRAPPER
------------------------------------------------------- */
.wrapper
  min-height 100%
  width 100%
  position relative
  overflow hidden


/* HEADER
------------------------------------------------------- */
header
  width 100%
  height 80px
  min-height 80px
  background-color white
  display block
  align-items flex-start
  padding 20px 40px
  position fixed
  top 0
  left 0
  overflow hidden
  box-shadow 0 -2px 10px rgba(0, 0, 0, 0.5)
  z-index 10
  transition all 0.5s linear
  @media screen and (max-width 1040px)
    padding 15px 20px
  a
    z-index 50
    .logo
      width calc((100% - 80px) - 120px)
      height 40px
      display flex
      background-size contain
      background-repeat no-repeat
      background-position 0% 100%
      white-space pre-line
      transition all 0.5s linear
      @media screen and (max-width 1040px)
        width calc((100% - 40px) - 120px)
      img
        height 100%
        width auto
      h1
        font-size 2em
        display flex
        align-self flex-end
        margin-bottom 0
        border 0px
        padding 0px
        transition all 0.5s linear
        @media screen and (max-width 570px)
          font-size 1.5em
        @media screen and (max-width 470px)
          font-size 1.2em
        @media screen and (max-width 370px)
          font-size 1em
        .title-cookie &
          font-size 2.8em
          position relative
          top 6px
          @media screen and (max-width 570px)
            font-size 2.4em
            top 8px
          @media screen and (max-width 470px)
            font-size 1.9em
            top 4px
          @media screen and (max-width 370px)
            font-size 1.4em
            top 3px
        .title-dancing &
          font-size 2.4em
          position relative
          top 3px
          @media screen and (max-width 570px)
            font-size 2.2em
          @media screen and (max-width 470px)
            font-size 1.7em
            top 0px
          @media screen and (max-width 370px)
            font-size 1.2em
  .hamburger
    outline none
    position absolute
    top 38px
    right 38px
    padding 0
    transition all 0.5s linear
    @media screen and (max-width 1040px)
      right 18px
    .hamburger-box
      outline none
      .hamburger-inner
        margin-top 6px
        height 2px
      .hamburger-inner::before,
      .hamburger-inner::after
        outline none
        height 2px
  .menu
    margin-top 30px
    ul
      list-style none
      li
        display flex
        flex-direction column
        text-align right
        a
          text-transform uppercase
          font-size 0.95em
          padding 7px 0 0 30px
          transition all 0.1s linear
          &:hover
            padding 7px 20px 0 30px
            transition all 0.1s linear
  .languages
    position absolute
    top 46px
    right 93px
    z-index 50
    transition all 0.5s linear
    @media screen and (max-width 1040px)
      right 63px
    &.moved
      right 33px
      @media screen and (max-width 1040px)
        right 13px
    ul
      display flex
      flex-direction row
      li
        height 10px
        position relative
        top 8px
        list-style none
        border-right 1px solid text
        &.active a
          color orange
        &:last-child
          border-right 0px
        a
          position relative
          top -8px
          font-size 0.8em
          text-transform uppercase
          padding 0 7px
  &.smaller
    height 50px
    min-height 50px
    padding 10px 40px
    @media screen and (max-width 1040px)
      padding 10px 20px
    a
      .logo
        height 30px
        transition all 0.5s linear
        h1
          font-size 1.8em
          transition all 0.5s linear
          @media screen and (max-width 570px)
            font-size 1.3em
          @media screen and (max-width 470px)
            font-size 1em
          @media screen and (max-width 370px)
            font-size 0.8em
          .title-cookie &
            font-size 2.2em
            @media screen and (max-width 570px)
              font-size 2em
            @media screen and (max-width 470px)
              font-size 1.7em
            @media screen and (max-width 370px)
              font-size 1.2em
          .title-dancing &
            font-size 2em
            @media screen and (max-width 570px)
              font-size 1.8em
            @media screen and (max-width 470px)
              font-size 1.5em
            @media screen and (max-width 370px)
              font-size 1em
    .hamburger
      top 14px
      transition all 0.5s linear
    .languages
      top 22px
      transition all 0.5s linear
  &.is-active
    height auto
    min-height 80px
    display block
    padding-bottom 30px
    transition all 0.5s linear
    &.smaller
      min-height 50px


/* CONTENT
------------------------------------------------------- */
.content
  width 100%
  height 100%
  position relative
  //top 80px
  padding-bottom 200px   // Altezza minima footer + padding
  @media screen and (max-width 768px)
    padding-bottom 340px   // Alzezza minima footer + padding
  a
    color orange
    font-weight 400
  
  ul
    padding-left 15px
    margin-bottom 1.6em
    list-style none
    li::before
      content ''
      display inline-block
      width 5px
      height 5px
      border-radius 50%
      margin-left -13px
      margin-right 8px
      position relative
      top -2px
      background-color grey
  figure
    width auto
    margin-bottom 10px
    img
      max-width 100%
    &.portraitLeft
      float left
      margin-right 15px
    &.portraitRight
      float right
      margin-left 15px
  .landscape
    width 100%
    //height calc(45vh + 80px)
    height 60vh
    display flex
    align-items center
    background-size cover
    background-repeat no-repeat
    @media screen and (max-width 1024px)
      calc(100vh - 80px)
    @media screen and (max-width 1040px)
      height 100vh
    &.home
      //height calc(100vh - 80px)
      height 100vh
      position relative
      line-height 2.7em
      img
        width 60px
        padding 10px
        position absolute
        bottom 20px
        left calc((100% - 60px) / 2)
        right calc((100% - 60px) / 2)
        cursor pointer
      .claim
        width auto
        max-width calc(100% - 80px)
        display none
        color rgba(255, 255, 255, 0.85)
        text-align center
        font-family "Open Sans Condensed"
        letter-spacing 0.2em
        font-size 2.7em
        line-height 1.1em
        text-transform uppercase
        margin 0 auto
        text-shadow 1px 1px 1px black
        @media screen and (max-width 1040px)
          max-width calc(100% - 40px)
        @media screen and (max-width 768px)
          font-size 2.4em
        @media screen and (max-width 667px)
          font-size 2.1em
        @media screen and (max-width 375px)
          font-size 1.8em
  .text
    width 100%
    max-width 1040px
    display block
    overflow hidden
    margin 0 auto
    padding 40px 20px 30px
    p:last-of-type
      margin-bottom 0.4em
    &.light-background
      background-color grey-background
      padding 100px 0 120px
      border-top 1px solid #d0d0d0
      border-bottom 1px solid #d0d0d0
      box-shadow 0px 0px 6px rgba(0, 0, 0, 0.3)
    .gallery
      display block
      background-color grey-background
      overflow hidden
      padding 10px
      margin 10px 0
      h1
        margin-bottom 0.35em
      h2
        margin-bottom 0.35em
      ol
        list-style-position inside
      &:first-of-type
        margin-top 50px
      .images
        width 100%
        max-width 1000px
        padding 0
        margin 0 auto
        margin-top 5px
        flex-wrap wrap
        a
          lost-column 1/7 7
          margin-bottom 0px
          @media screen and (max-width 950px)
            lost-column 1/6 6
          @media screen and (max-width 775px)
            lost-column 1/5 5
          @media screen and (max-width 625px)
            lost-column 1/4 4
          @media screen and (max-width 450px)
            lost-column 1/3 3
          img
            width 100%
            max-width 200px
            opacity 0.75
            transition all 0.5s linear
          &:hover
            img
              opacity 1
              transition all 0.5s linear
    
    .services
      h2
        margin-top 0
      .id
        display block
        height 70px
    .area
      display flex
      flex-direction row
      @media screen and (max-width 1040px)
        flex-direction column
      ul.services
        padding-left 19px
        margin-bottom 1.6em
        list-style none
        li::before
          content ''
          display inline-block
          width 5px
          height 5px
          border-radius 50%
          margin-left -17px
          margin-right 8px
          position relative
          top -2px
          background-color grey
      ul li a
        color text
        font-weight 300
        &:hover
          color orange
      > div
        lost-column 1/3 3
        @media screen and (max-width 1040px)
          lost-column 1
          margin-bottom 30px
          &:last-of-type
            margin-bottom 0
        > a
          display flex
          align-items baseline
          align-content center
          height auto
          color text
          strong
            font-weight 400
            color text
          &:hover
            color orange
      a.intro
        font-family "Open Sans Condensed"
        letter-spacing 0.2em
        text-transform uppercase
        font-size 1.2em
        font-weight 300
        color grey
        text-align center
        border-bottom 1px solid grey
        margin-bottom 30px
        @media screen and (max-width 1040px)
          text-align left
        &.active
          color orange
          border-bottom 1px solid orange
  .picture
    width 100%
    max-width 1000px
    height 100%
    padding-bottom 27%
    background-size cover
    background-repeat no-repeat
    margin 40px auto 30px
    @media screen and (max-width 1040px)
      background-attachment local
      padding-bottom 50%
    @media screen and (max-width 480px)
      background-attachment local
      padding-bottom 60%
    @media screen and (max-width 375px)
      background-attachment local
      padding-bottom 70%
  .map-canvas
    height 60vh
    position relative
    .gmnoprint
      top 80px !important
      .gm-style-cc
        display none !important
    @media screen and (max-width 1040px)
      padding-bottom 40%
    @media screen and (max-width 768px)
      padding-bottom 50%
    @media screen and (max-width 480px)
      padding-bottom 60%
    @media screen and (max-width 375px)
      padding-bottom 70%

/* FOOTER
------------------------------------------------------- */
footer
  width 100%
  height auto
  min-height 100px
  display flex
  flex-direction row
  justify-content space-between
  align-items center
  position absolute
  left 0
  bottom 0
  padding 20px 40px
  background-color white
  box-shadow 0 2px 10px rgba(0, 0, 0, 0.5)
  h1, h2, h3
    font-size 1.4em
    color grey
    margin-bottom 7px
    padding-bottom 2px
    border-bottom 0
    //border-bottom 1px solid grey
  p
    color grey
  @media screen and (max-width 1040px)
    padding 20px
  @media screen and (max-width 768px)
    min-height 240px
    flex-direction column
  .contact
    font-size 0.8em
    line-height 1.4em
    text-align left
    @media screen and (max-width 768px)
      text-align center
    p
      margin-bottom 0
  .social
    display inline-block
    a
      height 100%
      padding 2px
      img
        height 35px
        opacity 0.8
        transition all 0.2s linear
      &:hover
        img
          opacity 1
          transition all 0.2s linear
  .credits
    height auto
    font-size 0.8em
    line-height 1.4em
    text-align right
    @media screen and (max-width 768px)
      text-align center
    p
      margin-bottom 0
    a
      height 100%
      opacity 0.8
      transition all 0.2s linear
      &:hover
        opacity 1
        transition all 0.2s linear
      img
        width auto
        height 60px
        @media screen and (max-width 768px)
          width auto
          height 16px


/* SINGLE CLASSES
------------------------------------------------------- */
.empty-80
  width 100%
  height 80px
  display block
  overflow hidden
.hide-lg
  @media screen and (min-width 769px)
    display none
.hide-sm
  @media screen and (max-width 768px)
    display none
.orange
  color orange
.grey
  color grey
  